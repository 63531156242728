#load-more-btn {
  height: auto;
  min-height: 42px;
  color: white;
  font-weight: 400;
  font-size: 16px;
  padding: 6px 20px;
  border: solid 0px transparent;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);


}
#load-more-btn:disabled {
  background: #fff;
  color: #ccc;
  cursor: no-drop;
  height: auto;
  min-height: 42px;
}

#load-more-btn:hover:not([disabled]) {
  background-color: #6699FF;
  color: white;
  -webkit-transition: background-color 300ms ease-out;
  -moz-transition: background-color 300ms ease-out;
  -o-transition: background-color 300ms ease-out;
  transition: background-color 300ms ease-out;
}