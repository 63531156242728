#dao-intro-title {
color: white;
font-style: normal;
font-weight: 600;
font-size: 36px;
}

#dao-subtitle {
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}