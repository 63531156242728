/* .proposal {
  flex-grow: 1;
  font-size: 20px;
  color: white;
  display: block;
  text-align: center;
  border-radius: 8px;
  background-color: #537CCF;
  padding: 16px 30px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.status {
  min-width: 122px;
  font-size: 20px;
  color: white;
  display: block;
  text-align: center;
  border-radius: 8px;
  padding: 16px 30px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
} */

.status {
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-width: 122px;
  font-size: 20px;
  color: white;
  text-align: center;
  border-radius: 8px;
  padding: 16px 30px;
  box-sizing: border-box;
}


.status.status-0 {
 background-color: #84b7f7;
}
.status.status-1 {
  background-color: #72adf6;
}
.status.status-2 {
  background-color: #61a3f5;
}
.status.status-3 {
  background-color: #5099f4;
}
.status.status-4 {
  background-color: #4889db;
}
.status.status-5 {
  background-color: #407ac3;
}
.status.status-6 {
  background-color: #386baa;
}
.status.status-7 {
  background-color: #305b92;
}