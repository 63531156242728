#dao-carousel-subtite-0 {
  font-size:40px;
  color: white;
  text-shadow: black 1px 1px 12px;
  text-align: left;
}

#dao-carousel-title-0 {
  font-size:76px;
  color: white;
  text-shadow: black 1px 1px 12px;
  text-align: left;
}

#dao-carousel-subtite-1 {
  font-size:40px;
  color: white;
  text-shadow: black 1px 1px 12px;
  text-align: right;
}

#dao-carousel-title-1 {
  font-size:76px;
  color: white;
  text-shadow: black 1px 1px 12px;
  text-align: right;
}

#dao-carousel-subtite-2 {
  font-size:40px;
  color: white;
  text-shadow: black 1px 1px 12px;
  text-align: right;
}

#dao-carousel-title-2 {
  font-size:76px;
  color: white;
  text-shadow: black 1px 1px 12px;
  text-align: right;
}

#dao-carousel-subtite-3 {
  font-size:40px;
  color: white;
  text-shadow: black 1px 1px 12px;
  text-align: left;
}

#dao-carousel-title-3 {
  font-size:76px;
  color: white;
  text-shadow: black 1px 1px 12px;
  text-align: left;
}

.carousel .control-dots .dot.selected {
  background: #61A5FF;
  box-shadow: none;
  opacity: .9;
}