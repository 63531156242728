#landing-welcome-subtite {
  font-size: 28px;
  color: white;
  text-shadow:#4a4a4a 1px 1px 8px;
  font-weight: 600;
}

#landing-welcome-title {
  font-size: 58px;
  color: white;
  text-shadow:#4a4a4a 1px 1px 8px;
  font-weight: 600;
}